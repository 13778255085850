import "./styles.scss";

import React from "react";
import Vimeo from '@u-wave/react-vimeo';

const ModalVideo = ({ video_url, setIsOpen }) => {

    return (
    <div className="modal-video" onClick={() => setIsOpen(false)}>
      <div className="modal-video__close-btn" onClick={() => setIsOpen(false)}>
        <span></span>
        <span></span>
      </div>
      <div className="container">
        <div className="offset-lg-2 col-lg-8">
          <div className="modal-video__wrapper">
            <Vimeo
              video={video_url}
              muted={true}
              autoplay={true}
              showPortrait={false}
              showTitle={false}
              color="#e92539"
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalVideo;